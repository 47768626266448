import React, { useState,useEffect,useContext  } from "react";
import "../styles/components/navbar.css";
import { Link,useNavigate } from "react-router-dom";
import logo from '../images/logo.png';  
import {ReactComponent as MeltyFace} from '../assets/svg/melted-smiley-face.svg';
import { UserContext } from '../context/userContext';
import { faSun, faMoon, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Navbar() {
  const { theme, setTheme } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  let navigate = useNavigate();
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="navbar">
        <div className='logo'>
        <h1 onClick={() => navigate('/')} className={`lg-${theme === 'dark' ? 'dark' : 'light'}`}>
            AllKKA.
          </h1>
          <div className={`tagline tg-${theme === 'dark' ? 'dark' : 'light'}`}>
          <MeltyFace className="melty-face" />
          <p>
              By Music Lovers, For Music Lovers.
          </p>
          </div>
        </div>
        {isMobile ? (
        <MobileNavbar toggleTheme={toggleTheme} theme={theme} />
      ) : (
        <DesktopNavbar theme={theme} toggleTheme={toggleTheme} />
      )}
        
      </div>   
  );
}

export default Navbar;

const MobileNavbar = ({ toggleTheme, theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mobile-navbar">
      <FontAwesomeIcon icon={faBars} onClick={() => setIsOpen(!isOpen)} className="bars-icon" />
      <div className={`mobile-navbar-links ${isOpen ? 'nv-open' : 'nv-close'} mobile-nv-${theme}`}>
        <div className="mobile-navbar-header">
          <FontAwesomeIcon icon={faSun} onClick={toggleTheme} className="theme-toggler" />
          <FontAwesomeIcon icon={faXmark} onClick={() => setIsOpen(!isOpen)} className="nv-close-icon" />
        </div>
        <Link to="/festivals">Festivals</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Get In Touch</Link>
        <Link to="/create" className="get-started">Get Started</Link>
      </div>
    </div>
  );
}

const DesktopNavbar = ({theme, toggleTheme}) => {
  return (
    <div className="nav-links">
      <FontAwesomeIcon icon={theme === 'dark' ? faSun : faMoon} onClick={toggleTheme} className={`theme-toggler tg-${theme === 'dark' ? 'dark' : 'light'}`} />
          <div className={`nav-links-container nl-${theme === 'dark' ? 'dark' : 'light'}`}>
          <Link to="/festivals">Festivals</Link>
          <span className="divider">|</span>
          <Link to="/about">About</Link>
          <span className="divider">|</span>
          <Link to="/contact">Get In Touch</Link>
          </div>
          <Link to="create" className="get-started">
            Get Started
          </Link>
        </div>
  );
}